<template>
  <a
          :href="link"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
    target= '_blank'
  >
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
      ><span></span
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <i
      v-if="menu.submenu && menu.root && hasRootArrow"
      class="kt-menu__hor-arrow la la-angle-down"
    ></i>
    <i
      v-if="menu.submenu && !menu.root"
      class="kt-menu__hor-arrow la la-angle-right"
    ></i>
    <i
      v-if="menu.submenu && !menu.root"
      class="kt-menu__ver-arrow la la-angle-right"
    ></i>
  </a>
</template>

<script>
import { mapGetters } from "vuex";
// import { API_URL } from "@/common/config";

export default {
  name: "KTMenuItemText",
  components: {},
  props: {
    menu: Object,
    parentMenu: Object
  },
  methods: {
    /**
     * Get bullet class from config
     * @returns {string} class name
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${this.menu.bullet}`;
    },

  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if root arrow is enabled
     * @returns {*}
     */
    hasRootArrow() {
      return this.layoutConfig("header.menu.self.root-arrow");
    },
    link() {
      return  `${process.env.VUE_APP_BASE}${this.menu.path}`;
    }
  }
};
</script>
