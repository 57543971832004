import Swal from "sweetalert2";
<template>
    <div>
        <!--begin: Head -->
        <div
                class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                :style="{ backgroundImage: `url(${backgroundImage})` }"
        >
            <div class="kt-user-card__avatar">
                <img
                        class="kt-hidden"
                        alt="Pic"
                        src="@/assets/media/users/default.jpg"
                />
                <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                <span
                        class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
                >{{user.firstname.charAt(0)}}</span
                >
            </div>
            <div class="kt-user-card__name">{{user.name}}</div>
            <div class="kt-user-card__badge">
                <span class="btn btn-success btn-sm btn-bold btn-font-md" @click="go('contract-select')">Crear un nuevo documento</span>
            </div>
        </div>
        <!--end: Head -->
        <!--begin: Navigation -->
        <div class="kt-notification">
            <a href="#" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-document kt-font-warning"></i>
                </div>
                <div class="kt-notification__item-details" @click="go('contract-select')">
                    <div class="kt-notification__item-title kt-font-bold">
                        Crea tu nuevo contrato
                    </div>
                    <div class="kt-notification__item-time">Selecciona y crea un contrato de acuerdo a tus necesidades</div>
                </div>
            </a>
            <a href="#" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-analytics kt-font-info"></i>
                </div>
                <div class="kt-notification__item-details" @click="go('transaction')">
                    <div class="kt-notification__item-title kt-font-bold">
                        Mis contratos
                    </div>
                    <div class="kt-notification__item-time">Historial de contratos realizados</div>
                </div>
            </a>
            <a href="#" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-checkmark kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details" @click="go('payment')">
                    <div class="kt-notification__item-title kt-font-bold">
                        Mis pagos
                    </div>
                    <div class="kt-notification__item-time">Historial de pagos realizados</div>
                </div>
            </a>
            <a href="#" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-ligth"></i>
                </div>
                <div class="kt-notification__item-details" @click="go('profile')">
                    <div class="kt-notification__item-title kt-font-bold">Mi perfil</div>
                    <div class="kt-notification__item-time">
                        Administración de datos del usuario
                    </div>
                </div>
            </a>

            <!--      <a href="#" class="kt-notification__item">-->
            <!--        <div class="kt-notification__item-icon">-->
            <!--          <i class="flaticon2-rocket-1 kt-font-danger"></i>-->
            <!--        </div>-->
            <!--        <div class="kt-notification__item-details">-->
            <!--          <div class="kt-notification__item-title kt-font-bold">-->
            <!--            My Activities-->
            <!--          </div>-->
            <!--          <div class="kt-notification__item-time">Logs and notifications</div>-->
            <!--        </div>-->
            <!--      </a>-->
            <!--      <a href="#" class="kt-notification__item">-->
            <!--        <div class="kt-notification__item-icon">-->
            <!--          <i class="flaticon2-hourglass kt-font-brand"></i>-->
            <!--        </div>-->
            <!--        <div class="kt-notification__item-details">-->
            <!--          <div class="kt-notification__item-title kt-font-bold">My Tasks</div>-->
            <!--          <div class="kt-notification__item-time">-->
            <!--            latest tasks and projects-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </a>-->
            <!--      <a href="#" class="kt-notification__item">-->
            <!--        <div class="kt-notification__item-icon">-->
            <!--          <i class="flaticon2-cardiogram kt-font-warning"></i>-->
            <!--        </div>-->
            <!--        <div class="kt-notification__item-details">-->
            <!--          <div class="kt-notification__item-title kt-font-bold">Billing</div>-->
            <!--          <div class="kt-notification__item-time">-->
            <!--            billing & statements-->
            <!--            <span-->
            <!--              class="kt-badge kt-badge&#45;&#45;danger kt-badge&#45;&#45;inline kt-badge&#45;&#45;pill kt-badge&#45;&#45;rounded"-->
            <!--              >2 pending</span-->
            <!--            >-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </a>-->
            <div class="kt-notification__custom kt-space-between">
                <a
                        href="#"
                        v-on:click="onLogout()"
                        class="btn btn-label btn-label-brand btn-sm btn-bold"
                >Sign Out</a
                >
                <a
                        href="#"
                        v-on:click="go('plan')"
                        class="btn btn-clean btn-sm btn-bold"
                >Upgrade Plan</a
                >
            </div>
        </div>
        <!--end: Navigation -->
    </div>
</template>

<script>
    import {LOGOUT} from "@/store/auth.module";
    import storeService from "@/common/jwt.service";
    import Swal from "sweetalert2";

    export default {
        name: "KTDropdownUser",
        methods: {
            onLogout() {
                let timerInterval;
                Swal.fire({
                    title: 'Logout',
                    text: "Cerrando sesión",
                    timer: 1000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                        return this.$store
                                .dispatch(LOGOUT);
                    },
                    onClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                      this.$router.push({name: "login"})
                    }
                });
            },
            go(name) {
                this.$router.push({name})
            },
        },
        computed: {
            backgroundImage() {
                return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
            },
            user() {
                return storeService.getUser();
            },
        }
    };
</script>
